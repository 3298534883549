import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Space, Typography, Row, Popover, Image } from 'antd';
import styled from 'styled-components';

import AsnMenu from '../../Menu';
import { useAuth } from '../../../hooks/useAuth';
import { IMainLayout } from '../../../types/global';
import { COLORS, menuItemsHome, PATHS } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';

const { WHITE, GRAY, INDIGO, MAGNOLIA } = COLORS;

const { Header, Content, Sider } = Layout;
const { Title, Paragraph } = Typography;

const AsnSider = styled(Sider)`
  &.ant-layout-sider {
    background: ${WHITE};
    border-right: 1px solid ${GRAY};
  }
`;

const AsnHeader = styled(Header)`
  &.ant-layout-header {
    background-color: ${WHITE};
    border-bottom: 1px solid ${GRAY};
  }
`;

const AsnTitle = styled(Title)`
  &.ant-typography {
    color: ${INDIGO};
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: 500;
  }
`;

const HomepageLayout: React.FC<IMainLayout> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { logout, user } = useAuth();

  const handleMenuChange = (info: { key: string }): void => {
    const { key } = info;
    navigate(key);
  };
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  return (
    <Layout style={{ height: '100%', overflow: 'hidden' }} hasSider>
      <AsnSider>
        <Space
          direction='horizontal'
          style={{ padding: 15, width: '100%', borderBottom: `1px solid ${GRAY}`, height: 64, marginBottom: 24 }}
        >
          <Logo />
          <AsnTitle level={4}>ProfLab</AsnTitle>
        </Space>
        <AsnMenu
          selectedKeys={[pathname + decodeURIComponent(search)]}
          onSelect={(info): void => handleMenuChange(info)}
          className='homepage'
          items={menuItemsHome}
          mode='inline'
          inlineIndent={12}
        />
      </AsnSider>
      <Layout>
        <AsnHeader>
          <Space style={{ width: '100%', justifyContent: 'end' }}>
            <Row>
              <Popover
                content={
                  <Space direction='vertical'>
                    <Paragraph
                      style={{ cursor: 'pointer' }}
                      onClick={(): void => {
                        navigate(PATHS.CHANGE_PASSWORD);
                        setOpen(false);
                      }}
                    >
                      Change password
                    </Paragraph>
                    <Paragraph style={{ cursor: 'pointer' }} onClick={(): void => logout()}>
                      Log out
                    </Paragraph>
                  </Space>
                }
                trigger='click'
                open={open}
                onOpenChange={handleOpenChange}
                arrow={false}
              >
                <Row>
                  <Image
                    preview={false}
                    src={user?.photo ?? './assets/user.png'}
                    style={{ width: 32, height: 32, borderRadius: '50%', cursor: 'pointer' }}
                  />
                </Row>
              </Popover>
            </Row>
          </Space>
        </AsnHeader>
        <Content style={{ overflow: 'auto', background: `${MAGNOLIA}` }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default HomepageLayout;
