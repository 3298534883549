import styled from 'styled-components';
import { Col } from 'antd';

export const CardWrapper = styled(Col)`
  .ant-avatar {
    height: 64px;
    width: 64px;
    border-radius: 0%;
  }
  .ant-card-body {
    padding: 30px;
    border-radius: 4px;
  }
`;
