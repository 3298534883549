import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { PATHS } from '../constants';
import { useAuth } from '../hooks/useAuth';

export const PublicRoutes: React.FC = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to={`${PATHS.DASHBOARD}`} />;
  }

  return <Outlet />;
};
