import { COLORS } from '../../constants';

const { BLACK, LAVENDER, JACARTA, WHITE, GRAY, DARK_GRAY, MAGNOLIA } = COLORS;

export const antTheme = {
  components: {
    Input: {
      controlHeight: 40,
      colorPrimaryHover: DARK_GRAY,
      colorBgContainerDisabled: WHITE,
      colorTextDisabled: BLACK,
    },
    Select: {
      colorPrimaryHover: DARK_GRAY,
      colorTextPlaceholder: BLACK,
      colorIcon: BLACK,
      colorBgContainerDisabled: WHITE,
      colorTextDisabled: BLACK,
      controlHeight: 40,
    },
    Button: {
      colorPrimary: LAVENDER,
      colorPrimaryHover: JACARTA,
      colorPrimaryActive: JACARTA,
      borderRadius: 4,
      colorTextDisabled: WHITE,
      colorBgContainerDisabled: GRAY,
      colorLink: BLACK,
      colorLinkHover: LAVENDER,
      colorLinkActive: LAVENDER,
      fontSize: 16,
    },
    Checkbox: {
      colorPrimary: LAVENDER,
      colorPrimaryHover: LAVENDER,
    },
    Menu: {
      itemColor: DARK_GRAY,
      itemHoverColor: LAVENDER,
      fontSize: 16,
      itemSelectedColor: LAVENDER,
      itemHoverBg: WHITE,
      itemSelectedBg: WHITE,
      horizontalItemHoverColor: LAVENDER,
      horizontalItemSelectedColor: LAVENDER,
    },
    DatePicker: {
      colorPrimary: LAVENDER,
      colorPrimaryHover: DARK_GRAY,
      controlHeight: 40,
      colorBgContainerDisabled: WHITE,
      colorTextDisabled: BLACK,
    },
    Tag: {
      defaultBg: MAGNOLIA,
      borderRadiusSM: 2,
      colorText: DARK_GRAY,
      fontSizeSM: 14,
      fontSizeIcon: 22,
    },
    Spin: {
      colorPrimary: LAVENDER,
    },
  },
  token: {
    fontFamily: 'Roboto, sans-serif',
  },
};
