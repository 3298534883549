import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { PATHS } from '../constants';
import { HomepageLayout } from '../components';

export const PrivateRoutes: React.FC = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={PATHS.SIGNIN} />;
  }

  return (
    <>
      <HomepageLayout>
        <Outlet />
      </HomepageLayout>
    </>
  );
};
