import { useQuery, UseQueryResult } from '@tanstack/react-query';

import client from '../client';
import { DashboardData } from '../../components/dashboardData/types';

export const url = '/api/dashboard';

const GetAllDashboard = (options: { enabled?: boolean } = { enabled: true }): UseQueryResult<DashboardData> => {
  const result = useQuery<DashboardData>(
    [url],
    async () => {
      const response = await client.get<DashboardData>(url);
      return response.data;
    },
    options,
  );

  return result;
};

export default GetAllDashboard;
