import React, { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';

import { AsnTitle } from '../../../pages/Auth/styles';
import { COLORS } from '../../../constants';
import GetAllDashboard from '../../../api/dashboard';
import { DashboardData } from '../types';

const { BLACKANYLTIC, LAVENDER } = COLORS;
const DashboardResidence: FC = () => {
  const dataName = GetAllDashboard({ enabled: true }) as unknown as DashboardData;

  const data = dataName?.data?.result?.countriesStatistic || [];

  const config: ColumnConfig = {
    data,
    xField: 'type',
    yField: 'value',
    color: 'rgba(82, 119, 168, 1)',
    label: {
      position: 'top',
      callback: (count: number) => {
        return {
          content: count.toFixed(2),
        };
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      month: {
        alias: 'Month',
      },
    },
  };
  return (
    <div style={{ borderRadius: '10px', border: `1px solid  ${LAVENDER}`, margin: '16px 60px' }}>
      <AsnTitle
        level={5}
        style={{ color: `${BLACKANYLTIC}`, fontSize: '16px', borderBottom: '1px solid #E5E5EF', margin: '16px' }}
      >
        Country of residence
      </AsnTitle>
      <Column {...config} style={{ padding: '16px' }} />
    </div>
  );
};

export default DashboardResidence;
