import { Pie, PieConfig } from '@ant-design/plots';
import React, { FC } from 'react';

import { AsnTitle } from '../../../pages/Auth/styles';
import { COLORS } from '../../../constants';
import GetAllDashboard from '../../../api/dashboard';
import { DashboardData } from '../types';
const PierChart: FC = () => {
  const { BLACKANYLTIC, LAVENDER } = COLORS;

  const dataName = GetAllDashboard({ enabled: true }) as unknown as DashboardData;
  const data = dataName?.data?.result?.fieldOfStudyStatistic || [];
  const cfg: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    legend: false,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };

  const config = cfg;
  return (
    <div style={{ borderRadius: '10px', border: `1px solid  ${LAVENDER}`, margin: '48px 60px' }}>
      <AsnTitle
        level={5}
        style={{ color: `${BLACKANYLTIC}`, fontSize: '16px', borderBottom: '1px solid #E5E5EF', margin: '16px' }}
      >
        Field of study
      </AsnTitle>
      <Pie {...config} style={{ padding: '16px' }} />
    </div>
  );
};

export default PierChart;
