import styled from 'styled-components';
import { Row } from 'antd';

import { COLORS } from '../../../constants';
const { WHITE, LAVENDER, PAGINCOLOR, INDIGO } = COLORS;

export const PaginationRow = styled(Row)`
  .ant-pagination .ant-pagination-item-active {
    width: 30px;
    height: 30px;
    background-color: ${LAVENDER};
    border-color: inherit;
    border-radius: 50%;
    a {
      color: ${WHITE};
    }
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    color: ${PAGINCOLOR};
    font-size: 14px;
    font-weight: 600;
  }
`;
export const Description = styled(Row)`
  color: ${INDIGO};
  font-size: 14px;
  font-weight: 400;
`;
