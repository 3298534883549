import { Row } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { LAVENDER, INDIGO } = COLORS;

const Points = styled(Row)`
  justify-content: center;
  border: 1px solid ${LAVENDER};
  border-radius: 50%;
  color: ${INDIGO};
  font-size: 16;
  white-space: nowrap;
  width: 44px;
  height: 44px;
  align-items: center;
  flex-flow: inherit;
  font-size: 15px;
`;

export default Points;
