import React from 'react';
import { Row, Col, Typography, List } from 'antd';

import { COLORS } from '../../constants';

const { Title, Text, Paragraph } = Typography;
const { LAVENDER } = COLORS;

const PrivacyPolicy: React.FC = () => {
  return (
    <Row style={{ padding: '50px 0px', width: '100%' }}>
      <Col span={12} pull={6} push={6}>
        <Title underline level={3}>
          Privacy Policy
        </Title>
        <Row style={{ borderLeft: `2px solid ${LAVENDER}`, paddingLeft: 20 }}>
          <Text>
            This Privacy Policy is applicable for the ProfLab.am (hereinafter ‘ProfLab’). We appreciate your interest in
            our website, products, and services and we value the importance of protecting your privacy and personal
            data. To ensure transparency and security, our Privacy Policy as set forth below will inform you about the
            nature, scope and purpose of the personal data processed by us.
          </Text>
          <Title level={5}>1. What is Personal Data</Title>
          <Paragraph>
            Personal data means any information relating to an identified or identifiable natural person. ProfLab
            collects, processes, or uses personal data, if you provide it to us and give us your consent. Personal data
            may include name, age, email, phone, address, job title, profession and other data that you have provided
            while registering on our website, contacting us, or applying to receive our services using the contact
            forms, registration forms, or other service related forms on our website.
          </Paragraph>
          <Paragraph>
            For the best user experience and to improve our website and services, we also collect data sent by your web
            browser, e.g., information about your browser, your IP address, and your operating system.
          </Paragraph>
          <Title level={5}>2. Why We Need Your Personal Data</Title>
          <Paragraph>
            When you use services provided on the ProfLab website, you may be asked to provide certain personal
            information. We will store this personal data and hold it on computers or otherwise. We will use this data
            in the following ways:
          </Paragraph>
          <ul style={{ paddingInlineStart: 12, lineHeight: 1.57 }}>
            <List.Item>
              To register you with ProfLab website and provide services where you have registered to receive them. You
              can unsubscribe from these services and delete your account with ProfLab at any time;
            </List.Item>
            <List.Item>
              To provide you with information about new products and services and special offers we think you will find
              valuable;
            </List.Item>
            <List.Item>
              To answer any queries which you may send to us by ProfLab internal mail, external e-mail or our customer
              support system;
            </List.Item>
            <List.Item>To conduct customer satisfaction and related surveys for improvement of our services;</List.Item>
            <List.Item>To enter you in our competitions and promotions;</List.Item>
            <List.Item>To conduct scientific studies;</List.Item>
            <List.Item>To improve the security of our website;</List.Item>
            <List.Item>To meet our legal compliance obligations;</List.Item>
            <List.Item>For crime prevention and detection purposes;</List.Item>
            <List.Item>
              If you have consented to it, we may inform you about new products and services and special offers of
              selected third parties;
            </List.Item>
            <List.Item>
              Beyond these purposes, we process your data only if you have granted express consent for its stated
              purposes.
            </List.Item>
          </ul>
          <Title level={5}>To Whom Who We Disclose Your Personal Data To</Title>
          <Text>
            We do not sell, or trade your personal data to third parties. When you use ProfLab and our services, you
            decide for yourself, how much contact or other personal information you wish to display.
          </Text>
          <Paragraph>We may give information about you as follows:</Paragraph>
          <ul style={{ paddingInlineStart: 12, lineHeight: 1.57 }}>
            <List.Item>
              We may provide our third party service providers and processors with access to your personal information.
              These services providers may include: credit or debit card processing companies; our customer support
              center; marketing organizations, who may market and promote our services; internet service providers who
              provide internet connection and host our facilities; consumer research companies that assist us with
              understanding consumer interests by conducting surveys. We only share your personal information to the
              extent required for the performance of required by you services. We implemented safeguards to ensure that
              our service providers treat personal information in a way that is consistent with the terms of this
              Privacy Policy and that it is never used except to fulfill our services to our users. We may also disclose
              your personal information as permitted or required by law. For example, we will disclose personal
              information to those governmental bodies, who have authority to obtain it, in order to comply with a
              warrant or subpoena issued by a court of a competent jurisdiction, and to comply with record production
              requirements.
            </List.Item>
            <List.Item>
              Our website may also include social media features (e.g., share or like buttons). Such features are
              provided by third-party social media platforms such as LinkedIn, Twitter, or Facebook. Where data is
              collected this way, its processing is governed by the privacy policy of the respective social media
              platforms.
            </List.Item>
            <List.Item>
              Our content may link to third party website to provide relevant references. We are not responsible for
              such external content, which may contain separate privacy policies and data processing disclosures.
            </List.Item>
            <List.Item>
              In the event of sale of all or substantial part of the assets of DeltaQuest Media, we may transfer
              personal information in our control to a third party purchaser that agrees to use personal information for
              the same reasons as identified in this Privacy Policy.
            </List.Item>
            <List.Item>
              To our employees and agents to do any of the above on our behalf, now or in the future.
            </List.Item>
          </ul>
          <Text>
            If you choose not to provide certain personal information we request, you will still be able to visit the
            ProfLab website, but you may be unable to access some functionality or services offered by ProfLab.
          </Text>
          <Title level={5}>3. Your Consent</Title>
          <Text>
            By choosing to provide us with your personal information you are consenting to its use in accordance with
            the principles outlined in this Privacy Policy and as outlined at the time you are asked to provide any
            personal information. We may contact you by e-mail or otherwise in order to provide you with updates
            pertaining to the services, as well as information about additional offers, products or events that we
            believe may be of interest to you.
          </Text>
          <Title level={5}>4. Withdrawing Your Consent</Title>
          <Text>
            We provide you with a way to withdraw your consent for any of the communications received by you via
            external email or otherwise. If you have given us your email for purposes of communication or promotion, we
            will use your data to contact you or send promotional emails relating to your interests. You may revoke your
            consent to being contacted by email for promotional purposes any time, by clicking on an unsubscribe link or
            replying directly stating your wish to unsubscribe.
          </Text>
          <Title level={5}>5. Children & Education</Title>
          <Paragraph>
            We understand the importance of taking extra precautions to protect the privacy and safety of children using
            the ProfLab website and services. Children under the age of 13, or equivalent minimum age in the relevant
            jurisdiction, are not permitted to use our website and services, unless their parent provided verifiable
            consent.
          </Paragraph>
          <Paragraph>
            If we learn that we have collected the personal information of a child under 13, or equivalent minimum age
            depending on the jurisdiction, without verifiable parental consent, we will take steps to delete the
            information as soon as possible. If at any time parents need to access, correct, or delete data associated
            with their child&apos;s information, they may contact our Customer Support Center by following ‘Contact us’
            link provided at the footer or header on each page of the ProfLab website.
          </Paragraph>
          <Title level={5}>6. Technical & Organizational Data Protection</Title>
          <Paragraph>
            ProfLab implements commercially reasonable technical and organizational measures to protect your personal
            data against abuse and loss. We store such data in secure environments. We provide training to our employees
            on data protection best practices and require them to enter into a confidentiality agreement.
          </Paragraph>
          <Paragraph>
            While we use all reasonable efforts to protect your personal information, we cannot guarantee the absolute
            security of your data submitted through our website.
          </Paragraph>
          <Title level={5}>7. Cookies</Title>
          <Paragraph>
            When you visit ProfLab, our system automatically collects information about your visit, such as your browser
            type, your IP address, and the referring website. This collection may be done in conjunction with our
            vendors and partners (see data processors list below). We may receive from them general demographic or usage
            data of our site visitors. We do not use automatically collected information to identify you personally
            without collecting additional consent.Such external data processing is governed by the privacy policies of
            these third-party providers.
          </Paragraph>
          <Paragraph>
            Such information is typically collected using web cookies, web or email pixels (also known as beacons),
            embedded hyperlinks, and similar tools. All commonly used browsers can generally be set to disable or delete
            individual cookies. See below for the types of cookies we use and their respective purposes.
          </Paragraph>
          <Paragraph>
            Required Cookies: enable the navigation and basic functionality of the website, e.g., access to protected
            areas of our website.
          </Paragraph>
          <Paragraph>
            Functional Cookies: allow us to analyze your website usage and your selections on the website (e.g. your
            login name, language, or region), so we can save these settings and offer you a more personalized
            experience.{' '}
          </Paragraph>
          <Paragraph>
            Advertising Cookies: allow us to better assess the effectiveness of our content marketing and advertising
            efforts. These cookies are provided by our third-party partners to analyze and track site visit and signups
            stemming from advertising. We do not share your personal information (such as name or email) to third-party
            providers outside of site visit data collected directly by such Advertising Cookies, though your site visit
            data may be linked with other personal information collected elsewhere by such third-party providers.{' '}
          </Paragraph>
          <Paragraph>
            See below for descriptions about the cookies used on our website and how you can opt out from them.{' '}
          </Paragraph>
          <Paragraph>
            Our website uses <u>Google Analytics</u>, a web analysis service of Google, Inc. (“Google”). Learn more from
            their <u>Terms of Service</u> and <u>Privacy Policy</u>. Opt-out: Download the browser plugin “Google
            Analytics Opt-out Browser Add-on” <u>here</u>.
          </Paragraph>
          <Paragraph>
            Our website uses <u>Google AdWords</u> Conversion Tracking cookie to measure and optimize the performance
            and user experience related to our ads in Google Search or selected Google Display Network sites. Learn more
            about Google’s privacy policy <u>here</u>. Opt-out: Visiting <u>Google’s Ads Settings</u>.
          </Paragraph>
          <Title level={5}>8. Transfer of Your Personal Information</Title>
          <Text>
            Some parties that process or store personal information may be located in jurisdictions outside your country
            of residence. Therefore, your information may be processed and stored in these jurisdictions and, as a
            result, foreign governments, courts, or law enforcement or regulatory agencies may be able to obtain
            disclosure of your information through the laws in these countries.
          </Text>
          <Title level={5}>9. Notification of Changes</Title>
          <Text>
            If we decide to change our Privacy Policy, we will post those changes on the ProfLab website, so you are
            always aware of what information we collect, how we use it, and under which circumstances, if any, we
            disclose it. If at any point we decide to use personally identifiable information in a manner different from
            that stated at the time it was collected, we will notify you by way of external email or otherwise. You will
            have a choice as to whether or not we use your information in this different manner. We will use information
            in accordance with the Privacy Policy under which the information was collected. You agree that by using the
            ProfLab website you are expressly and affirmatively consenting to our use and disclosure of the information
            that you provide, and consenting to receive emails, and other communications as described above.
          </Text>
          <Title level={5}>10. Complaints Resolution</Title>
          <Paragraph>
            We cannot ensure that all of your private communications and other personal information will never be
            disclosed in ways not otherwise described in this Privacy Policy. Therefore, although we are committed to
            protecting your privacy, we do not promise, and you should not expect, that your personal information or
            private communications will always remain private. As a user of the ProfLab website, you understand and
            agree that you assume all responsibility and risk for your use of the ProfLab website, the Internet
            generally, and the documents you post or access and for your conduct on and off the ProfLab website.
          </Paragraph>
          <Paragraph style={{ marginBottom: 0 }}>
            We are committed to providing a fair and responsive system for handling and resolving complaints concerning
            the handling of personal information. You have a right to complain and to have your complaint handled
            efficiently if you are concerned about our handling of your personal information. Upon request, our Data
            Protection Officer will provide you with information as to whether and what personal data we store in
            relation to you. Should your personal data be incorrect, you may have it rectified. You may also revoke your
            consent to use your personal data in the future, in whole or in parts, or request deletion of your personal
            data. We believe that any complaint that we may receive is a valuable opportunity to improve the services
            that we deliver to you and to maintain your confidence in our services. If at any time you wish to lodge a
            complaint in respect of the handling, use or disclosure of your personal information by us, you may do so by
            contacting our Customer Support Center by using our Contact information.
          </Paragraph>
        </Row>
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
