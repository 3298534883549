import React from 'react';
import { Modal, Typography, Space } from 'antd';

import { COLORS } from '../../constants';
import AsnButton from '../Button';
import { IUbdateModal } from '../../types/components';

const { Text } = Typography;
const { INDIGO } = COLORS;

const UbdateUserModal: React.FC<IUbdateModal> = ({ openDeleteModal, setOpenDeleteModal, title, handleDelete }) => {
  return (
    <Modal
      open={openDeleteModal}
      centered
      footer={false}
      bodyStyle={{ padding: '50px 0px' }}
      onCancel={(): void => setOpenDeleteModal(false)}
    >
      <Space direction='vertical' style={{ width: '100%' }} align='center' size={32}>
        <Text style={{ fontSize: 16, color: `${INDIGO}` }}>{title}</Text>
        <Space size={32}>
          <AsnButton onClick={(): void => setOpenDeleteModal(false)}>No</AsnButton>
          <AsnButton type='primary' onClick={(): void => handleDelete()}>
            Yes
          </AsnButton>
        </Space>
      </Space>
    </Modal>
  );
};

export default UbdateUserModal;
