import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import client from '../client';
import { ApiResponse, GetAllUsersParams, GetAllUsersResult } from '../../pages/userManage/type';

export const url = '/api/user/admin';
const getAllUsers = (params: GetAllUsersParams, options?: UseQueryOptions<ApiResponse, Error>): GetAllUsersResult => {
  const result = useQuery<ApiResponse, Error>(
    [url, params],
    async () => {
      const response = await client.post<ApiResponse>(`${url}`, {
        limit: params.limit,
        offset: params.offset,
        orderBy: {
          column: 'createdAt',
          direction: 'DESC',
        },
      });
      return response.data;
    },
    options,
  );

  const { data, isSuccess, refetch } = result;

  const refetchAllData = (): void => {
    void refetch();
  };

  return {
    ...result,
    data: isSuccess ? data : { data: [], count: 0, has_more: false },
    refetchAllData,
  };
};

export default getAllUsers;
