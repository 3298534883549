import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Col, Space, Input, Typography, Spin } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';

import { useResetPassword, useCheckToken } from '../../api';
import { AsnButton, AsnForm, ExpiredLink } from '../../components';
import { COLORS, passwordRegExp, validate_messages } from '../../constants';
import { ResetPasswordVariables } from '../../types/api/auth';
import { ReactComponent as AuthSvg } from '../../assets/icons/auth.svg';

import { AsnTitle, LogoWrapper } from './styles';

const { Title, Paragraph } = Typography;
const { LAVENDER } = COLORS;

const ResetPassword: React.FC = () => {
  const [isValidToken, setIsValidToken] = useState<boolean | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const [form] = AsnForm.useForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const { mutate: resetpassword } = useResetPassword();
  const { mutate: chechkToken } = useCheckToken();

  const rulesConfirmPassword: Rule[] = [
    {
      required: true,
    },
    {
      pattern: passwordRegExp,
    },
    ({ getFieldValue }): RuleObject => ({
      async validator(_, value): Promise<void> {
        if (getFieldValue('password') === value) {
          return await Promise.resolve();
        }
        return await Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    }),
  ];

  const onFinish = (values: ResetPasswordVariables): void => {
    resetpassword({
      ...values,
      token,
    });
  };

  useEffect(() => {
    if (token) {
      chechkToken(
        { token },
        {
          onSuccess: (data) => {
            setIsValidToken(data?.data?.valid);
            if (!data?.data?.valid) {
              setEmail(data?.data?.email as string);
            }
          },
        },
      );
    }
  }, [token]);

  if (isValidToken === undefined) {
    return (
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Spin />
      </Row>
    );
  }

  return (
    <>
      {isValidToken ? (
        <Row style={{ width: '100%', height: '100%' }}>
          <Col span={12}>
            <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
              <AsnForm
                layout='vertical'
                form={form}
                validateMessages={validate_messages}
                style={{ maxWidth: '460px', width: '35vw' }}
                onFinish={(values): void => onFinish(values as ResetPasswordVariables)}
              >
                <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
                  Enter Your password
                </Title>
                <Paragraph style={{ marginBottom: '32px', fontSize: 16 }}>
                  The password should have at least 8 characters
                </Paragraph>
                <AsnForm.Item
                  name='password'
                  label='New Password'
                  rules={[{ required: true }, { pattern: passwordRegExp }]}
                >
                  <Input.Password placeholder='Enter Password' />
                </AsnForm.Item>
                <AsnForm.Item
                  name='repeatPassword'
                  label='Confirm Password'
                  rules={rulesConfirmPassword}
                  dependencies={['password']}
                >
                  <Input.Password placeholder='Confirm Password' />
                </AsnForm.Item>
                <AsnForm.Item>
                  <AsnButton type='primary' htmlType='submit' style={{ width: '100%' }}>
                    Reset Password
                  </AsnButton>
                </AsnForm.Item>
              </AsnForm>
            </Row>
          </Col>
          <LogoWrapper span={12}>
            <Space direction='vertical' style={{ alignItems: 'center' }} size={32}>
              <AuthSvg />
              <AsnTitle level={1}>ProfLab</AsnTitle>
            </Space>
          </LogoWrapper>
        </Row>
      ) : (
        <ExpiredLink
          text={` Your password reset link is expired. Click on Resend to receive a new link.`}
          buttonText='Resend'
          email={email}
          isValidToken={isValidToken}
          reset={true}
        />
      )}
    </>
  );
};

export default ResetPassword;
