import { ReactComponent as UserSvg } from '../assets/icons/userManagment.svg';
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg';

export const PATHS = {
  DASHBOARD: '/dashboard',
  SIGNIN: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/restore-password',
  VERIFY: '/confirm-email',
  PRIVACY_POLICY: '/privacy-policy',
  USERMANAGMENT: '/user_managment',
  CHANGE_PASSWORD: '/change-password',
};

export const AUTH_KEYS = {
  USER: 'user',
  TOKEN: 'token',
};

export const COLORS = {
  LAVENDER: '#68468E',
  DARK_BLUE: '#5277A8',
  YELLOW: '#D9CA6D',
  FUZZY: '#D96868',
  INDIGO: '#283A52',
  WHITE: '#FFFFFF',
  GRAY: '#C7C7C7',
  DARK_GRAY: '#808080',
  PLATINUM: '#E6E7E8',
  BLACK: 'rgba(0, 0, 0, 0.88)',
  JACARTA: '#462F60',
  ERROR: '#F03738',
  SUCCESS: '#4CAF50',
  MAGNOLIA: '#FCFBFD',
  DISABLED: 'rgba(0, 0, 0, 0.04)',
  BLACKANYLTIC: 'rgba(17, 27, 35, 1)',
  PAGINCOLOR: '#353537',
};

export const validate_messages = {
  required: '${label} is required',
  pattern: {
    mismatch: '${label} requirements were not met',
  },
};

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,50}$/;

export const whiteSpacesRegexp = /^\S+$/;

export const menuItemsHome = [
  {
    key: '/user_managment',
    label: 'User Management',
    icon: <UserSvg />,
  },
  {
    key: '/dashboard',
    label: 'Dashboard',
    icon: <Dashboard />,
  },
];
export const columnsUserTable = [
  {
    title: 'User name',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    width: '15%',
    editable: false,
  },
  {
    title: 'Added date',
    dataIndex: 'date',
    editable: false,
  },
  {
    title: 'Last activity',
    dataIndex: 'last',
    editable: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    editable: false,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
  },
  {
    title: 'Chosen CP',
    dataIndex: 'chosen',
    editable: false,
  },
];
