import { Button } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';
const { PLATINUM, BLACK, GRAY, DARK_GRAY, WHITE, DARK_BLUE } = COLORS;

const AsnButton = styled(Button)`
  font-weight: 600;
  &.ant-btn-link:hover {
    span {
      text-decoration: underline;
    }
  }
  &.export {
    &.ant-btn-primary {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      background-color: ${PLATINUM};
      &:hover {
        background-color: ${GRAY};
      }
      span {
        color: ${BLACK};
      }
    }
    .ant-btn-icon:not(:last-child) {
      margin-inline-end: 0;
      margin-inline-start: 8px;
    }
  }
  &.text {
    font-weight: 500;
    &.ant-btn {
      font-size: 14px;
      padding: 0;
      margin-bottom: 32px;
      color: ${DARK_GRAY};
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.circle {
    &.ant-btn-default:disabled {
      border: 1px solid ${DARK_BLUE};
      height: 32;
      width: 32;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      background: ${WHITE};
      align-items: center;
      white-space: nowrap;
      padding: 3px;
    }
    &.ant-btn-default {
      border: 1px solid ${DARK_BLUE};
      height: 32;
      width: 32;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      background: ${WHITE};
      align-items: center;
      white-space: nowrap;
      padding: 3px;
    }
  }
`;

export default AsnButton;
