import React from 'react';
import { Col, Row } from 'antd';

import DashboardCard from '../../components/dashboardData/dashboardCard';
import DashboardAnaltyics from '../../components/dashboardData/dashbardAnaltics';
import DashboardAnalyticsCouching from '../../components/dashboardData/analyticsCouching';
import PierChart from '../../components/dashboardData/pierChart';
import DashboardAge from '../../components/dashboardData/dashboardAge';
import DashboardResidence from '../../components/dashboardData/dashboardResidence';
const Dashboard: React.FC = () => {
  return (
    <Row>
      <Col span={24}>
        <DashboardCard />
      </Col>
      <Col span={24}>
        <DashboardAnaltyics />
      </Col>
      <Col span={24}>
        <DashboardAnalyticsCouching />
      </Col>
      <Col span={24}>
        <DashboardAge />
      </Col>
      <Col span={24}>
        <DashboardResidence />
      </Col>
      <Col span={24}>
        <PierChart />
      </Col>
    </Row>
  );
};

export default Dashboard;
