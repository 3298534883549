import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Space, Input, Typography } from 'antd';

import { useSignIn } from '../../api';
import { AsnButton, AsnForm } from '../../components';
import { COLORS, validate_messages, PATHS } from '../../constants';
import { ISignInValues } from '../../types/auth';
import { ReactComponent as AuthSvg } from '../../assets/icons/auth.svg';

import { AsnTitle, LogoWrapper } from './styles';

const { Title } = Typography;
const { LAVENDER } = COLORS;

const SignIn: React.FC = () => {
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: signIn } = useSignIn();

  const onFinish = (values: ISignInValues): void => {
    signIn(values, {
      onSuccess: () => {
        navigate(`${PATHS.USERMANAGMENT}`);
      },
    });
  };

  return (
    <Row style={{ width: '100%', height: '100%' }}>
      <LogoWrapper span={12}>
        <Space direction='vertical' style={{ alignItems: 'center' }} size={32}>
          <AuthSvg />
          <AsnTitle level={1}>ProfLab</AsnTitle>
        </Space>
      </LogoWrapper>
      <Col span={12}>
        <Row style={{ width: '100%', height: '100%' }} justify={'center'} align={'middle'}>
          <AsnForm
            layout='vertical'
            form={form}
            validateMessages={validate_messages}
            style={{ maxWidth: '460px', width: '35vw' }}
            onFinish={(values): void => onFinish(values as ISignInValues)}
          >
            <Title level={3} style={{ textAlign: 'center', color: `${LAVENDER}`, marginBottom: '32px' }}>
              Admin log in
            </Title>
            <AsnForm.Item name='email' label='Email address' rules={[{ required: true, type: 'email' }]}>
              <Input placeholder='Email address' />
            </AsnForm.Item>
            <AsnForm.Item name='password' label='Password' rules={[{ required: true }]} style={{ marginBottom: 4 }}>
              <Input.Password placeholder='Password' />
            </AsnForm.Item>
            <AsnButton className='text' type='text' onClick={(): void => navigate(`${PATHS.FORGOT_PASSWORD}`)}>
              Forgot Password ?
            </AsnButton>
            <AsnForm.Item>
              <AsnButton type='primary' htmlType='submit' style={{ width: '100%' }}>
                Log In
              </AsnButton>
            </AsnForm.Item>
          </AsnForm>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
