import { Menu } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { LAVENDER, WHITE, DARK_GRAY } = COLORS;

const AsnMenu = styled(Menu)`
  &.landing {
    font-weight: 700;
    &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
      border-bottom-width: 0;
      border-bottom-color: transparent;
    }
    &.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
      border-bottom-width: 0;
      border-bottom-color: transparent;
    }
  }
  &.homepage {
    svg {
      rect {
        fill: ${WHITE};
      }
      path {
        fill: ${DARK_GRAY};
      }
    }
    &.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
      font-weight: 600;
      svg {
        rect {
          fill: ${LAVENDER};
        }
        path {
          fill: ${WHITE};
        }
      }
    }
    &.ant-menu-light .ant-menu-item-selected {
      font-weight: 600;
      svg {
        rect {
          fill: ${LAVENDER};
        }
        path {
          fill: ${WHITE};
        }
      }
    }
    .ant-menu-item-selected {
      border-right: 3px solid ${LAVENDER};
      border-radius: 0;
      margin-inline: 2px;
      width: 100%;
    }
  }
`;

export default AsnMenu;
