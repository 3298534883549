import React, { Key, ReactNode, useState } from 'react';
import { Table, Form, Typography, Row, Col, Pagination, message } from 'antd';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

import { AsnTitle } from '../Auth/styles';
import { AUTH_KEYS, COLORS, columnsUserTable } from '../../constants';
import GetAllUsers from '../../api/user';
import UbdateUserModal from '../../components/UbdateUserModal';
import useUpdateUserAdminStatus from '../../api/user/patchUser';
import useResendEmail from '../../api/auth/useResendEmail';
import AsnButton from '../../components/Button';

import { Item, User } from './type';
import { Description, PaginationRow } from './styles';

const { LAVENDER, DARK_GRAY } = COLORS;
const { Paragraph } = Typography;

const UserPages: React.FC = () => {
  const originData: Item[] = [];

  const [params, setParams] = useState({
    limit: 10,
    offset: 1,
    orderBy: {
      column: 'createdAt',
      direction: 'DESC',
    },
  });

  const handlePageChange = (newPageNumber: number): void => {
    const newOffset = (newPageNumber - 1) * params.limit;
    setParams({ ...params, offset: newOffset });
  };

  const { data } = GetAllUsers(params);
  const [openDeleteModal, setOpenDeleteModal] = useState<string | boolean>(false);
  const [userStatus, setUserStatus] = useState<string | boolean>('');
  const [userEmail, setUserEmail] = useState<string | boolean>('');
  const [expandedKey, setExpandedKey] = useState<string | number | null>(null);

  const { mutate: updateAdminStatus } = useUpdateUserAdminStatus();
  const queryClient = useQueryClient();

  const { mutate: resendEmail } = useResendEmail();
  const [form] = Form.useForm();

  const handleDelete = (): void => {
    const idAsNumber = openDeleteModal as unknown as number;

    if (userStatus === 'INACTIVE') {
      resendEmail(
        { email: userEmail as string },
        {
          onSuccess: () => {
            setOpenDeleteModal(!openDeleteModal);
          },
        },
      );
    } else if (userStatus === 'BLOCKED' || userStatus === 'ACTIVE') {
      void updateAdminStatus(
        { id: idAsNumber },
        {
          onSuccess: () => {
            setOpenDeleteModal(!openDeleteModal);
            void queryClient.invalidateQueries(['/api/user/${id}/admin']);
            void queryClient.invalidateQueries(['/api/user/admin']);
          },
        },
      );
    }
  };

  const showModal = (id: number, status: string, email: string): void => {
    if (typeof id === 'string') {
      setOpenDeleteModal(id);
      setUserStatus(status);
      setUserEmail(email);
    }
  };

  if (data && 'result' in data) {
    const userData = data.result as User[];

    userData.forEach((col: User) => {
      const date = moment(col.createdAt);
      const dateUpdate = moment(col.updatedAt);

      const statusButton =
        col.status === 'ACTIVE' ? (
          <Typography.Link
            onClick={(): void => showModal(col.id, col.status, col.email)}
            style={{ color: '#68468E ', fontStyle: 'italic', cursor: 'pointer', fontWeight: '600' }}
          >
            BLOCK
          </Typography.Link>
        ) : col.status === 'BLOCKED' ? (
          <Typography.Link
            onClick={(): void => {
              showModal(col.id, col.status, col.email);
            }}
            style={{ color: '#68468E ', fontStyle: 'italic', cursor: 'pointer', fontWeight: '600' }}
          >
            UNBLOCK
          </Typography.Link>
        ) : (
          <Typography.Link
            onClick={(): void => {
              showModal(col.id, col.status, col.email);
            }}
            style={{ color: '#68468E ', fontStyle: 'italic', cursor: 'pointer', fontWeight: '600' }}
          >
            RESEND
          </Typography.Link>
        );
      const professions = Array.isArray(col?.professions)
        ? col.professions.map((t: { [x: string]: Key | null | undefined }) => <Row key={t?.id}>{t?.title}</Row>)
        : [];

      originData.push({
        key: col.id,
        name: col ? `${col.firstName} ${col.lastName}` : '',
        email: col.email,
        date: date.format('YYYY-MM-DD '),
        last: dateUpdate.format('YYYY-MM-DD '),
        status: col.status,
        chosen: (col.selectedProfession as unknown as { title: string })?.title ?? '',
        professions: professions as React.ReactNode[],
        edit: statusButton,
        id: '',
      });
    });
  }

  const toggleExpand = (key: string | number): void => {
    setExpandedKey((prevKey) => (prevKey === key ? null : key));
  };
  const itemRender = (
    page: number,
    type: 'prev' | 'next' | 'page' | 'jump-prev' | 'jump-next',
    element: ReactNode,
  ): ReactNode => {
    if (type === 'prev') {
      return ' < prev';
    }
    if (type === 'next') {
      return 'next >';
    }
    return element;
  };

  const exportAndDownload = async (): Promise<void> => {
    await fetch('https://api.proflab.am/api/user/download-excel', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_KEYS.TOKEN) || ''}`,
      },
      method: 'POST',
    })
      .then(async (resp) => await resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const aLink = document.createElement('a');
        aLink.style.display = 'none';
        aLink.href = url;
        aLink.download = 'User.xlsx';
        document.body.appendChild(aLink);
        aLink.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => message.error('Something went wrong !!', 2));
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <AsnTitle level={5} style={{ fontSize: '20px', margin: '16px', color: `${LAVENDER}`, fontWeight: '500' }}>
            Users
          </AsnTitle>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Paragraph
            style={{
              fontSize: '14px',
              margin: '16px',
              color: '#263238',
              height: '40px',
              background: '#F6F4F9',
              padding: '8px',
            }}
          >
            Total number of users: {data?.count}
          </Paragraph>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: ' end', padding: '0 16px' }}>
          <AsnButton
            onClick={(): void => {
              void exportAndDownload();
            }}
            style={{ color: `${DARK_GRAY}` }}
          >
            Export Data
          </AsnButton>
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={originData}
          columns={columnsUserTable}
          pagination={false}
          style={{ padding: '16px' }}
          expandable={{
            expandIconColumnIndex: 7,
            columnTitle: 'CPs',
            columnWidth: '50px',
            expandedRowRender: (record) => (
              <Description style={{ display: 'flex', gap: '10px' }}>{record.professions}</Description>
            ),
            onExpand: (_, record) => toggleExpand(record?.key),
            expandedRowKeys: expandedKey !== null ? [expandedKey] : [],
          }}
        />
        <PaginationRow justify={'center'}>
          <Pagination
            current={Math.floor(params.offset / params.limit) + 1}
            pageSize={params.limit}
            total={data?.count ? data?.count : 0}
            onChange={handlePageChange}
            showSizeChanger={false}
            itemRender={itemRender}
          />
        </PaginationRow>
      </Form>
      {openDeleteModal && (
        <UbdateUserModal
          openDeleteModal={!!openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          title={'Are you sure to change the status of user?'}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default UserPages;
