import React from 'react';
import { Layout } from 'antd';

import { IMainLayout } from '../../../types/global';

const { Content } = Layout;

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  return (
    <Layout style={{ height: '100%' }}>
      <Content>{children}</Content>
    </Layout>
  );
};

export default MainLayout;
