import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from '../constants';
import SignIn from '../pages/Auth/SignIn';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import VerifyEmail from '../pages/Auth/VerifyEmail';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import UserManagment from '../pages/userManage';
import Dashboard from '../pages/Dashboard';
import ChangePassword from '../pages/Auth/ChangePassword';

import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';

export const routers = createBrowserRouter([
  {
    element: <PublicRoutes />,
    children: [
      {
        path: PATHS.SIGNIN,
        element: <SignIn />,
      },
      {
        path: PATHS.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: PATHS.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: PATHS.VERIFY,
        element: <VerifyEmail />,
      },
      {
        path: PATHS.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: PATHS.DASHBOARD,
        element: <Dashboard />,
      },

      {
        path: PATHS.USERMANAGMENT,
        element: <UserManagment />,
      },
      {
        path: PATHS.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
    ],
  },
]);
