import { useMutation, MutationFunction, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import client from '../client';
import { UpdateUserAdminStatusVariables, UserData } from '../../pages/userManage/type';

const updateUserAdminStatus: MutationFunction<UserData, UpdateUserAdminStatusVariables> = async ({ id }) => {
  if (id === null) {
    throw new Error('Invalid ID');
  }
  const url = `/api/user/${id}/admin`;
  const response: AxiosResponse<UserData> = await client.patch(url, {
    id: id,
  });
  const data = response.data;
  return data;
};

const useUpdateUserAdminStatus = (): UseMutationResult<UserData, unknown, UpdateUserAdminStatusVariables, unknown> => {
  return useMutation<UserData, unknown, UpdateUserAdminStatusVariables>(updateUserAdminStatus);
};

export default useUpdateUserAdminStatus;
