import React from 'react';
import { Avatar, Card, Row } from 'antd';

import { ReactComponent as ActiveUsers } from '../../../assets/icons/activeUsers.svg';
import { ReactComponent as Coaching } from '../../../assets/icons/coaching.svg';
import { ReactComponent as Cours } from '../../../assets/icons/cours.svg';
import { ReactComponent as Job } from '../../../assets/icons/job.svg';
import { CardWrapper } from '../../../pages/Dashboard/styles';
import { COLORS } from '../../../constants';
import GetAllDashboard from '../../../api/dashboard';
import { DashboardDataCard } from '../types';
const { Meta } = Card;
const { YELLOW, LAVENDER, DARK_BLUE, FUZZY } = COLORS;

const DashboardCard: React.FC = () => {
  const { data: dataName } = GetAllDashboard({ enabled: true });
  const dashboardData = dataName as unknown as DashboardDataCard;
  return (
    <Row justify={'center'} gutter={[57, 16]} style={{ width: '100%', padding: '48px 0' }}>
      <CardWrapper>
        <Card bordered={true} style={{ width: 230, height: 120, border: `1px solid ${LAVENDER}` }}>
          <Meta
            avatar={<Avatar icon={<ActiveUsers />} />}
            title={dashboardData?.result?.activeUsersCount === 0 ? '0' : dashboardData?.result?.activeUsersCount}
            description='Active Users'
          />
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card bordered={true} style={{ width: 230, height: 120, border: `1px solid ${YELLOW}` }}>
          <Meta
            avatar={<Avatar icon={<Coaching />} />}
            title={
              dashboardData?.result?.allUsersByCarerPathCount === 0
                ? '0'
                : dashboardData?.result?.allUsersByCarerPathCount
            }
            description='AI Coaching'
          />
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card bordered={true} style={{ width: 230, height: 120, border: `1px solid ${DARK_BLUE}` }}>
          <Meta
            avatar={<Avatar icon={<Cours />} />}
            title={dashboardData?.result?.courseClickCount === 0 ? '0' : dashboardData?.result?.courseClickCount}
            description='Course Click'
          />
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card bordered={true} style={{ width: 230, height: 120, border: `1px solid ${FUZZY}` }}>
          <Meta
            avatar={<Avatar icon={<Job />} />}
            title={dashboardData?.result?.jobClickCount === 0 ? '0' : dashboardData?.result?.jobClickCount}
            description='Job Click'
          />
        </Card>
      </CardWrapper>
    </Row>
  );
};

export default DashboardCard;
